import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Slider } from "./pages/mainPage/Slider/slider";
import { Courses } from "./pages/mainPage/Courses/courses";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { SuspenseSpinner } from "./components/Spinners/suspenceSpinner";

import "./style/styles.scss";
import "react-toastify/dist/ReactToastify.css";

const DocPage = lazy(() => import("./pages/documentPage/docPage"));
const ErrorMessage = lazy(() => import("./pages/page404/page404"));
const Profile = lazy(() => import("./pages/profilePage/profile"));
const LessonPage = lazy(() => import("./pages/LessonPage/LessonPage"));
const DynamicPages = lazy(() => import("./pages/dynamicPages/dynamicPages"));

// Валідні значення для courses і pages в одному масиві
const validPages = [
  "integration_course",
  "lang_dutch_course",
  "pro_restaurant",
  "pro_logistics",
  "pro_retail",
  "pro_hotel",
  "pro_entrepreneurship",
  "pro_food_industry",
  "pro_economy",
  "cabinet",
  "about_us",
  "news",
  "faq",
  "survey",
];

// Прості числа від 1 до 50 для module, topic та lesson
const validNumbers = Array.from({ length: 50 }, (_, i) => i + 1); // Числа від 1 до 50

// Одна функція для валідації
const validateRoute = (type, params) => {
  const { courseName, module, topic, lesson, pageName } = params;
  if (type === "course") {
    return (
      validPages.includes(courseName) &&
      validNumbers.includes(parseInt(module)) &&
      validNumbers.includes(parseInt(topic)) &&
      validNumbers.includes(parseInt(lesson))
    );
  }
  if (type === "page") {
    return validPages.includes(pageName);
  }
  return false;
};

const LessonWrapper = () => {
  const { courseName, module, topic, lesson } = useParams();
  if (!validateRoute("course", { courseName, module, topic, lesson })) {
    return <ErrorMessage />;
  }

  return (
    <section className="app">
      <Header mod={courseName} type="lesson" />
      <LessonPage
        courseName={courseName}
        module={module}
        topic={topic}
        lesson={lesson}
      />
      <Footer />
    </section>
  );
};

const DynamicWrapper = () => {
  const { pageName } = useParams();

  // Сторінка в стадії розробки (Service Unavailable - error 503)
  if (pageName === "pro_economy") {
    return <ErrorMessage type="error503" />;
  }

  if (!validateRoute("page", { pageName })) {
    return <ErrorMessage />;
  }

  return (
    <section className="app">
      <Header mod={pageName} type="page" />
      <DynamicPages pageName={pageName} />
      <Footer />
    </section>
  );
};

export const App = () => {
  return (
    <Suspense fallback={<SuspenseSpinner />}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <section className="app">
                <Header mod="main" />
                <Slider />
                <Courses />
                <Footer />
              </section>
            }
          />

          <Route path="/:pageName" element={<DynamicWrapper />} />

          <Route
            path="/courses/:courseName/:module/:topic/:lesson"
            element={<LessonWrapper />}
          />

          <Route
            path="/cabinet"
            element={
              <section className="app">
                <Header mod="cabinet" />
                <Profile />
                <Footer />
              </section>
            }
          />

          <Route
            path="/documents"
            element={
              <section className="app">
                <Header mod="documents" />
                <DocPage />
                <Footer />
              </section>
            }
          />

          <Route path="*" element={<ErrorMessage />} />
        </Routes>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Router>
    </Suspense>
  );
};
