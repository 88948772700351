import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Context } from "../../../index";
import { useIsMobile } from "../../../utils/dom/isMobile";
import { Spinner } from "../../../components/Spinners/spinner";

import integra from "../../../lib/cards/integration3.png";
import flags from "../../../lib/cards/flags2.png";
import hotel from "../../../lib/cards/hotel2.png";
import resta from "../../../lib/cards/restaurant3.png";
import logist from "../../../lib/cards/logistics.png";
import retail from "../../../lib/cards/reatail4.png";
import heyDutch from "../../../lib/logo-icons/HeyDutch-300x87.png";

import "./coursesStyle.scss";

export const Courses = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const { auth } = useContext(Context);
  const { t } = useTranslation();

  const [isCoursesOpen, setIsCoursesOpen] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const containerRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleOpenCourse = (courseName) => {
    if (currentUser) {
      // navigate(`/courses/${courseName}`);
      navigate(`/cabinet`);
    } else {
      const targetElement = document.getElementById("login-btn"); // Отримуємо елемент за ID
      if (targetElement) {
        targetElement.click(); // Виконуємо клік на елементі
      } else {
        console.warn("Елемент з id 'login-btn' не знайдено.");
      }
    }
  };

  const handleNavigate = (pageName) => {
    navigate(`/${pageName}`);
  };

  const courseData = [
    {
      img: integra,
      courseName: "integration_course",
      id: 2,
    },
    { img: hotel, courseName: "pro_hotel", id: 3 },
    { img: resta, courseName: "pro_restaurant", id: 4 },
    { img: logist, courseName: "pro_logistics", id: 5 },
    {
      img: retail,
      courseName: "pro_entrepreneurship",
      id: 6,
    },
    { img: retail, courseName: "pro_retail", id: 7 },
    { img: retail, courseName: "pro_food_industry", id: 8 },
    {
      img: flags,
      courseName: "pro_economy",
      id: 1,
    },
  ];

  if (!courseData) {
    return <Spinner type="courseData (courses.js)" />;
  }

  return (
    <main className="courses" id="courses">
      <article className="courses__container">
        <div className="courses__wrap">
          <p className="courses__title">{t("choose_course")}</p>
        </div>

        <article className="courses__row">
          {courseData.map((course) => (
            <div
              className="courses__card"
              id={course.courseName}
              key={course.courseName}
            >
              <div className="courses__card--front">
                <p>{t(course.courseName)}</p>
              </div>
              <div className="courses__card--back">
                <ul className="courses__card-ul">
                  <li
                    className="courses__card-li"
                    onClick={() => handleNavigate(course.courseName)}
                  >
                    {t("about_the_course")}
                  </li>

                  <li
                    className="courses__card-li"
                    onClick={() => {
                      handleOpenCourse(course.courseName);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {currentUser ? t("enter") : t("login")}
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </article>

        {/* <section className="courses__container-col--2">
          <button onClick={() => handleNavigate("about_us")}>
            {t("about_us")}
          </button>
          <button onClick={() => handleNavigate("faq")}>
            {t("faq_title")}
          </button>
          <button onClick={() => handleNavigate("news")}>{t("news")}</button>
        </section> */}
      </article>
    </main>
  );
};
