// Конфігурація курсів
export const coursesConfig = [
  {
    category: "language_and_integration_courses",
    courses: [
      // { id: "1", name: "lang_dutch_course" },
      { id: "2", name: "integration_course" },
    ],
  },
  {
    category: "professional_courses",
    courses: [
      { id: "3", name: "pro_restaurant" },
      { id: "4", name: "pro_logistics" },
      { id: "5", name: "pro_retail" },
      { id: "6", name: "pro_hotel" },
      { id: "7", name: "pro_entrepreneurship" },
      { id: "8", name: "pro_food_industry" },
      { id: "9", name: "pro_economy" },
    ],
  },
];

export const tutorials = {
  ua: "https://www.youtube.com/watch?v=QhA_c3Rlu5w",
  ru: "https://www.youtube.com/watch?v=yjLa4UUKll8",
};

export const tuitionFee = 900;
