import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import { App } from "./App";
import "./style/scroller.scss";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Створюємо контекст
export const Context = createContext(null);

const AppProvider = ({ children }) => {
  // локальні клейми користувача
  const [localClaims, setLocalClaims] = useState({});
  const [userData, setUserData] = useState(null);

  // Запит до Firestore для отримання даних користувача
  const fetchUserData = async (uid) => {
    try {
      const userRef = doc(firestore, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserData(userSnap.data());
      } else {
        console.log("Користувача не знайдено");
      }
    } catch (error) {
      console.error("Помилка отримання даних користувача: ", error);
    }
  };

  // Підписка на зміни авторизації
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        setLocalClaims(claims);

        // Отримуємо дані користувача з Firestore
        await fetchUserData(user.uid);
      } else {
        setLocalClaims({});
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Context.Provider
      value={{
        auth,
        storage,
        firestore,
        functions,
        localClaims,
        setLocalClaims,
        userData,
        setUserData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppProvider>
    <App />
  </AppProvider>
);
